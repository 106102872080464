import Analytics from '@/domain/Analytics';

const isDevelopment = process.env.NODE_ENV !== 'production';

export default function ApplicationCommons() {
  if (isDevelopment) {
    return null;
  }

  return (
    <>
      <Analytics />
    </>
  );
}
